import React, { useState, useEffect  } from 'react'
import ContentWrapper from '../layouts/contentWrapper'
import Loading from '../../components/Loading';
import setStatistic from '../../services/apiStatistic'
import { Connect, Disconnect, socket } from '../../services/chatAPI';
import api from '../../services/api'
import { IDCOMPANY, ReadDataStorage} from './../../services/auth'
import MessageView from '../../components/messageView/messageview';
import MessageList from '../../components/messageView/messagelist';
import ShowMessages from './../../components/Exceptions'
import { format, fromUnixTime  } from 'date-fns'
import LOGO from './../../assets/logo.png'
import { useChatData } from './../../components/messageView/chatContext'
import beepSound from './../../assets/audio/chat-alert.mp3';
import {setDataDB, getDataDB, dbDelete } from '../../services/idb'
import { getCookieMessage,setCookieMessage } from './../../components/Cookies';
import {generateThumbnail, RepeatRemove,PopulateConversation} from './../../components/Utils'

export default function Messages( {props}) {
    const [loading, setLoading]                         = useState(true)

    const { settings, setSettings, setChatList, unread,setConversation,
        setUnread, handleReload, to, loadingChat }      = useChatData()

    const handleUnread = () => { 
        if (unread <= 0)
            document.title = '55Zap | Painel de Controle'
        else 
            document.title = '(' + unread + ') 55Zap | Painel de Controle'
    }

    const playBeep = () => {
        const audio = new Audio(beepSound);
        audio.play();
    }

    const callMsg = async(data) => {
        try {
            //POR SEGURANÇA, CASO O OBJETO VENHA SEM INSTANCIA, FINALIZA
            if (data === undefined)
                return

            //VERIFICO SE TEM UM CHAT PARA ATUALIZAR, OU CRIO UM NOVO
            let _chats = await getDataDB('HEADER',data.idcompany) || []

            let _checkChat = _chats.find(_filter => {
                return _filter.idmessagehistoryheader === data.idmessagehistoryheader
            })
            //ATUALIZA O CHAT SE ENCONTRAR
            let _body = ['vcard','image','sticker','video','ptt','document','audio'].includes(data.type) ? JSON.stringify({"mimetype" : data._data.mimetype,
                                                                                                                                      "type"     : data._data.type,
                                                                                                                                      "filesize" : data._data.filesize,
                                                                                                                                      "caption"  : data.body,
                                                                                                                                      "filename" : data._data?.filename || '',
                                                                                                                                      "file"     : data._data.body}) : data.body

            let _to = (data.isGroup ? data?.contact?.id?.user ||  data.to.user : data.contact?.number ||  data.to?.user || data.to).replace(/[\W_,[a-z]+/g,"")

            if (_checkChat) {

                _checkChat.body                        = _body
                _checkChat.hasmedia                    = ['vcard','image','sticker','video','ptt','document','audio'].includes(data.type)
                _checkChat.isreceive                   = data?._data?.id?.fromMe || data?.fromMe
                _checkChat.type                        = data.type
                _checkChat.update_at_text              = format(new Date(fromUnixTime(data?.timestamp || data.t)),'dd/MM/yyyy HH:mm:ss')
                _checkChat.idmessagehistoryheader      = data.idmessagehistoryheader
                _checkChat.update_at                   = new Date(fromUnixTime(data?.timestamp || data.t)) 
                _checkChat.unread                      = parseInt(data.idmessagehistoryheader) === parseInt(getCookieMessage('idchat')) ? 0 : data.unread    
                _checkChat.idautomationchatheadhistory = data.idautomationchatheadhistory || 0 
                _checkChat.photo                       = data.urlphoto
                _checkChat.notifyusergroup             = data.notifyusergroup
                _checkChat.isgroup                     = data.isGroup
                //GRAVA MENSAGENS NO IDB E SETA NA USESTATE
                _chats.sort((a, b) => new Date(b.update_at) - new Date(a.update_at));
                setDataDB('HEADER',_chats,handleReload,data.idcompany)
            } else {
                let _chat = await getDataDB('HEADER',data.idcompany) || []
                if (typeof _chat !== 'object')
                    _chat = JSON.parse(_chat)
         
                _chat.unshift({
                    body                        : _body,
                    mimetype                    : data._data?.mimetype,
                    type                        : data._data.type,
                    filesize                    : data._data?.filesize || 0,
                    caption                     : data.body,
                    filename                    : data._data?.filename || '',
                    file                        : data._data.body, 
                    hasmedia                    : ['vcard','image','sticker','video','ptt','document','audio'].includes(data.type),
                    isreceive                   : data?._data?.id?.fromMe || data?.fromMe,
                    update_at_text              : format(new Date(fromUnixTime(data?.timestamp || data.t)),'dd/MM/yyyy HH:mm:ss'),
                    idmessagehistoryheader      : data.idmessagehistoryheader,
                    update_at                   : new Date(fromUnixTime(data?.timestamp || data.t)),  
                    unread                      : data.unread,
                    notifyname                  : data._data.notifyName,
                    to                          : _to,
                    photo                       : data.urlphoto,
                    starthuman                  : data.starthuman,
                    isgroup                     : data.isGroup,
                    notifyusergroup             : data.notifyusergroup,
                    original_number             : data.original_number,
                    number                      : data.number,
                    endhuman                    : data.endhuman,
                    tags                        : data.tags,
                    edited                      : data.edited,
                    revoked                     : data.revoked,
                    idautomationchatheadhistory : data.idautomationchatheadhistory || 0
                })
                //GRAVA MENSAGENS NO IDB E SETA NA USESTATE
                await setDataDB('HEADER',_chat,handleReload,data.idcompany)
            }
            if (parseInt(data.idmessagehistoryheader) === parseInt(getCookieMessage('idchat'))) {// ATUALIZA O CHAT SE ESTIVER ABERTO 
                let PDF  
                if ((data.type === 'document') && (data._data?.mimetype === 'application/pdf')) {
                    PDF = await generateThumbnail(data._data.body) 
                    data.thumbnail = PDF.thumbnail || null
                    data.pages     = PDF.pages || 0
                }
                
                
                let _keyNumber = data.original_number.replace(/[\W_,[a-z]+/g,"") + '_' + _to
              
                let _populate  = PopulateConversation(data,!(data?._data?.id?.fromMe || data?.fromMe))
                let _myChat = [...(await getDataDB(_keyNumber,ReadDataStorage(IDCOMPANY)) || []), _populate]

                _myChat  = RepeatRemove(_myChat)
               
                setDataDB(_keyNumber,_myChat,null,ReadDataStorage(IDCOMPANY))
                setConversation( _myChat)

                api.post('/clearUnread',{number : data.original_number.replace(/[\W_,[a-z]+/g,""), to : _to})
                   .then(async(response) => {

                   }).catch((error) => {
                       ShowMessages(error)
                   })  
            } else {
                //SE TIVER UM NOME, TIVER AUTORIZADA A PERMISSÃO E PERMITO O ALERTA... ENVIA A MSG
                if (data._data?.notifyName) {
                    if (settings.allowNotifications) {
                        if (Notification.permission === "granted") {
                            let _msg = data.body
                            switch (data.type) {
                                case 'vcard':
                                    _msg = 'Contato'
                                    break;
                                case 'location':
                                    _msg = 'Localização'
                                    break;
                                case 'image':
                                    _msg = 'Imagem'
                                    break;
                                case 'sticker':
                                    _msg = 'Sticker'
                                    break;
                                case 'ptt':
                                    _msg = 'Áudio'
                                    break;
                                case 'chat':
                                    _msg = 'Mensagem'
                                     break;
                                default:
                                    _msg = 'Documento'
                                    break;
                            }

                            new Notification("55Zap Comunicação Social", {
                                body: data._data.notifyName + '\n' + _msg,
                                icon : LOGO
                            });
                        } else {
                            Notification.requestPermission().then((permission) => {
                                if (permission === "granted") {
                                console.log("Permissão concedida para exibir notificações.");
                                }
                            })
                        }
                    }
                }
  
                if (settings.allowSounds)
                    playBeep()
            }
        } catch(e) {
            console.log(e)
        }
    }

    const _getData = async(isLoading) => { 
        if (isLoading)
            setLoading(true)
        await api.get('/getChatList/1')
                 .then((response) => { 
                    let _unread = response.data.data.reduce((_total,_item) => {
                        return _total + _item.unread
                    },0)
                 
                    //GRAVA MENSAGENS NO IDB E SETA NA USESTATE
                    setDataDB('HEADER',response.data.data,handleReload,ReadDataStorage(IDCOMPANY))
                    setChatList(response.data.data)
                    setUnread(_unread)
                    if (socket === null) {
                        Connect()
                        socket.on('message', msg => {
                            callMsg(msg)
                        })
                        .on('update',msg => {
                            console.log("atualizando chats")
                            _getData(false)//RETIRAR DAQUI
                            if (settings.allowNotifications) {
                                if (Notification.permission === "granted") {
                                    if (msg.showmessage) {
                                        new Notification("55Zap Comunicação Social", {
                                            body: 'Você recebeu uma atualização',
                                            icon : LOGO
                                        });
                                    }
                                } else {
                                    Notification.requestPermission().then((permission) => {
                                        if (permission === "granted") 
                                            console.log("Permissão concedida para exibir notificações.");
                                    })
                                }
                            }

                            if (settings.allowSounds && msg.showmessage)
                                playBeep()
                        })

                    }

                    setLoading(false)
                 }).catch(error => {
                    setLoading(false)
                    ShowMessages(error)
                 })  
    }

    useEffect(() => {        
        const handleFocus = () => {
            //  inputRef.current.focus();
        };

        window.addEventListener('blur', handleFocus);
    
        if (JSON.stringify(settings) !== '{}') {
            _getData(true)
        }

        return () => {
            window.removeEventListener('blur', handleFocus);
            setCookieMessage('idchat',0)
            Disconnect()
        };
    }, [settings])

    useEffect(() => {   
        const getSettings = async () => {
            await api.get('/company/' + ReadDataStorage(IDCOMPANY))
                     .then(async(response) => {
                        setSettings(response.data?.settings || {})
                     }).catch((error) => {
                        ShowMessages(error)
                     })  

            let _version = await getDataDB('DBVersion',ReadDataStorage(IDCOMPANY)) || ''
            if ((_version === '') || (_version !== process.env.REACT_APP_DB_VERSION)) {
                await dbDelete(ReadDataStorage(IDCOMPANY))  
                setDataDB('DBVersion',process.env.REACT_APP_DB_VERSION,null,ReadDataStorage(IDCOMPANY)) 
            }

        }



        getSettings()
        setStatistic('maintenance\\messages')
    }, [])

    useEffect(() => {
        handleUnread()
    },[unread])


    return (
        <ContentWrapper>
            {loading && (
                <Loading loading={loading} /> 
            )}

                <div className={`content-box-chat ${(loading ? "hidden" : "")}`}>       
                    <div style={{width:"300px"}}>
                        <MessageList props={props}/>
                    </div>

                    {loadingChat ? 
                        <div style={{width:"calc(100% - 310px)", justifyContent:"center", display:"flex", flexDirection:'column', zIndex:999, alignItems:"center"}}>
                            <Loading loading={loadingChat}/>
                        </div>
                    :
                        (to !== "") ? 
                            <div style={{width:"calc(100% - 310px)", justifyContent:"space-between", display:"flex", flexDirection:'column', zIndex:999}}>
                                <MessageView viewMode={false} />
                            </div> 
                        : 
                            <div style={{width:"calc(100% - 310px)", justifyContent:"center", display:"flex", flexDirection:'column', zIndex:999, alignItems:"center"}}>
                                <img src={LOGO} style={{maxWidth:"400px"}} alt=""/>
                            </div>
                        }
                </div>

        </ContentWrapper>
    )
}