import React, { useCallback, useEffect, useRef, useState } from 'react';

import P from 'prop-types';
import KSContainer from './../../pages/layouts/Container';
import Header from './Header';
import Sidebar from './Sidebar';

import { NotificationContainer } from 'react-notifications';

import {checkPermission} from './../../components/security'

import logo from './../../assets/logo-404.png';

//import Joyride from 'react-joyride';
import { ACCEPT_TERMS, ReadDataStorage } from '../../services/auth';
import ModalAcceptedTerms from '../modals/modal-accepted-terms';
import ChatDataProvider from './../../components/messageView/chatContext'
import Messages from "./../../pages/maintenance/messages";

const BasePage = (props) => {
  const [company, setCompany] = useState(-1);
  const [reset, setReset] = useState(false);
  const [pages, setPages] = useState(-1);

  const [reload, setReload] = useState(false);
  const [isAttendance, setIsAttendance] = useState(false)

  const steps = useRef([
    {
      target: '#main-logo',
      content:
        'Olá, seja bem vindo ao 55Zap, sua plataforma integrada de comunicação.\r\nSiga as instruções do nosso tour interativo para iniciar o sistema.',
      disableBeacon: true,
      placement: 'top',
    },
    {
      target: '#btn-sair1',
      content: 'Após o uso do sistema, você pode usar essa opção para efetuar logoff do sistema.',
    },
    {
      target: '#btn-language',
      content: 'Nessa opção, você pode alterar entre os idiomas disponíveis no sistema.',
    },
    {
      target: '#btn-company',
      content:
        'Caso você tenha mais de uma empresa cadastrada no sistema, nesta opção você poderá escolher em qual empresa irá trabalhar.',
    },
    {
      target: '#btn-alert',
      content: 'Neste item, você receberá todas as notificações e alertas enviados pelo sistema.',
    },
    {
      target: '#btn-news',
      content: 'Este e o seu canal de atendimento, novidades do sistema e notícias.',
    },
    {
      target: '#div-expire',
      content: 'Esse item te mantém informado sobre o vencimento do seu plano contratado.',
    },
    {
      target: '.profile-wrap',
      content:
        'Este e o seu menu de opções de usuário, nele você consegue visualizar o seu perfil, alterar sua senha, e visualizar suas faturas.',
    },
    {
      target: '#menu-12',
      content:
        'Depois de te apresentar a tela inicial, agora vamos para a sua primeira configuração do sistema, tenha em mão o seu telefone conectado ao WhatsApp.',
    },
  ]);

  const handleReload = () => {
        setReload((state) => !state);
  };

  const hasTermsAccepted = useCallback(() => {
        return ReadDataStorage(ACCEPT_TERMS) === 'true';
  }, [reload]);

  useEffect(() => {
    setReset(true);
    setIsAttendance(!checkPermission(1,15))
  }, [company]);

  useEffect(() => {}, [pages]);

  if (!hasTermsAccepted()) {
    return (
      <>
        <ModalAcceptedTerms showModal={true} handleReload={handleReload} />
        <NotificationContainer />
      </>
    );
  }

  
  return (
    <>
      <div className="wrapper">
        {/* <Joyride
          steps={steps.current}
          run={false}
          continuous={true}
          disableCloseOnEsc={true}
          showProgress={true}
          locale={{ back: 'Voltar', close: 'Fechar', last: 'Último', next: 'Próximo', open: 'Abrir', skip: 'Pular' }}
          styles={{
            overlay: { zIndex: 2000 },
            options: { zIndex: 3000 },
            buttonNext: {
              backgroundColor: '#104e5a',
            },
            buttonBack: {
              backgroundColor: '#104e5a',
              borderRadius: '3px',
              color: '#fff',
            },
          }}
        /> */}
        <Header history={props.history} callbackCompany={setCompany} isAttendance={isAttendance} setIsAttendance={setIsAttendance}/>
        {(!isAttendance) && (
            <Sidebar ActualCompany={company}/>
        )}

        <section className={`section-container ${isAttendance ? 'section-container-attendance' : ''}`}>
            <div id="content-tab" className={`${pages > 0 || isAttendance? 'heigth100' : 'hidden'}`}>
                {(isAttendance) ? 
                    <ChatDataProvider>
                        <Messages/>
                    </ChatDataProvider>
                :
                    <KSContainer reset={reset} callbackReset={setReset} callbackPages={setPages} /> 
                }
          </div>
          <div className='box-base'>
            <img alt="LOGO"id="main-logo" src={logo} style={{ maxWidth: 40 + '%', width: 30 + '%' }} />
          </div>
        </section>
      </div>
      <NotificationContainer />
    </>
  );
};

export default BasePage;

BasePage.propTypes = {
  history: P.string,
};
