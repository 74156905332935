import React, { useEffect, useState  } from 'react'
import { useParams } from 'react-router-dom'

import logo from './../../assets/logo-404.png'
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import api from '../../services/api';
import ShowMessages from '../../components/Exceptions'

export default function RedirectGroup () {
    const [showLogo55ZapRedirectGroup, setShowLogo55ZapRedirectGroup] = useState(false)
    const { hash } = useParams();

    useEffect(() => {
        const getData = async() => {
            await api.post('/getHashGroup',{hash})
                     .then((response) => {
                        if (response.status === 200) {
                            if (response.data.status === 404)
                                window.location.href = 'https://55zap.com.br'
                            else {
                                let _data = response.data;
                                setShowLogo55ZapRedirectGroup(_data.showLogo)

                                for (const [idx, _seo] of _data.SEO.entries()) {
                                    switch (parseInt(_seo.SEOCompany)) {
                                        case 0:
                                            const options = {
                                                autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
                                                debug: false, // enable logs
                                            };

                                            ReactPixel.init(_seo.TAG,{},options);
                                            ReactPixel.pageView();
                                            setTimeout(() => {

                                            },500)
                                            break;
                                        case 1:
                                            ReactGA.initialize(_seo.TAG);
                                            ReactGA.pageview(window.location.pathname);
                                            setTimeout(() => {
                                                
                                            },500)
                                            break;
                                        default:
                                            break;
                                    }
                                }    

                                window.location.href = _data.inviteCode
                            }
                        } else 
                            window.location.href = 'https://55zap.com.br'
                     }).catch(error => {
                        ShowMessages(error)
                     }) 
        }
        
        getData()

      
    }, [])

    return (
            <section>
                {showLogo55ZapRedirectGroup && (
                    <div  style={{display:"flex",width:"100%",justifyContent:"center"}}>
                        <img src={logo} alt={process.env.REACT_APP_NAME}/>
                    </div>
                )}
                <div style={{display:"flex",width:"100%", height:"88vh",alignItems:"center",justifyContent:"center", flexDirection:"column", gap:"15px"}}>
                   
                    <span><i className='fa fa-spin fa-spinner' style={{fontSize:"42px"}}/></span>
                    <div style={{textAlign:"center"}}>
                        <span style={{fontSize:"25px"}}>Aguarde... Você está sendo redirecionado ao grupo.</span>
                    </div>
                    
                </div>
            </section>

                
    )   
}