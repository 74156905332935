import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import Draggable from 'react-draggable';

import { TagsInput } from "react-tag-input-component";

import api from '../../services/api';
import setStatistic from '../../services/apiStatistic'
import loadingButton  from '../layouts/Buttons'


class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalImport({showModal, handleCloseModal}) {
    const [showResult, setShowResult]        = useState(false)

    const [importUnnamed, setImportUnnamed]  = useState(true)
    const [useTagsFile, setUseTagsFile]      = useState(true)
    const [tags, setTags]                    = useState('')
    const [file, setFile]                    = useState('')
    const [tagBlock, setTagBlock]            = useState(false)

    const [dataResult, setDataResult]        = useState([])
      
    const [dataTags, setDataTags]            = useState([])
    const [tag, setTag]                      = useState('')

    const handleShow = () => {setShowResult(false)}

    const addValidationTag = (_tag) => {
        if (_tag.trim() === '')
            return false
        _tag = _tag.toString().toUpperCase()
        return !tags.includes(_tag)
    }

    const handleTagInput = (value) => { 
        value = value.map(function(x){ return x.toString().toUpperCase().replace(/[^a-zA-Z0-9_]/g,''); })     
        
        if (value === '')
            return false  
        
        if (tags.length !== value.length)
            setTags(value)
    }

    const setData = (obj,clear) => {
        setShowResult(false)
        setTags([])
        setUseTagsFile(true)
        setImportUnnamed(true)
        setTagBlock(false)
    }

    const download = async() => {
        await api.get('/resourses/modelimport')
                 .then(response => {
                        var link = document.createElement('a');
                        link.download = 'modelimport.xlsx';
                        link.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + response.data;
                        document.body.appendChild(link);
                        link.click()
                        document.body.removeChild(link);
                })
                .catch( error => { 
                    ShowMessages(error)
            })
    }

    const handleSubmit = async(event) => {
        event.preventDefault()
        if ((tags.length <= 0) && (!useTagsFile) && (!tagBlock)){
            ShowMessages({status : 500, message : "Nenhuma tag foi informada!"})
            return
        }

        if ((!file) || (file === '')) {
            ShowMessages({status : 500, message : "Nenhum arquivo foi informado!"})
            return
        }

        if (file.size > 10e6) {
            ShowMessages({status : 500, message : "O arquivo informado não pode ser maior que 10Mb!"})
            return 
        }
        loadingButton(true,document.querySelector('#submit-import'),document.querySelector('#submit-import').children[0].classList,document.querySelector('#submit-import').lastChild.nodeValue)
        try {

            const formData = new FormData();
            let _tags = tags.map(name => name.toUpperCase());

            formData.append('file', file);
            formData.append('importUnnamed', importUnnamed ? 1 : 0);
            formData.append('useTagsFile', useTagsFile ? 1 : 0);
            formData.append('tagBlock', tagBlock ? 1 : 0);
            formData.append('tags', _tags);

            await api.post('/api/v1/importFile', formData, {headers: { "Content-Type": "multipart/form-data"}})
                      .then(response => {
                        console.log(response)
                         ShowMessages(response)
                         if (response.ok) {   
                             if (response.data.status === 200) {
                                setShowResult(true)   
                                setDataResult(response.data.report)
                             }
                        }
                        loadingButton(false,document.querySelector('#submit-import'),'fa fa-save','Importar')        
                       })
                       .catch( error => {  
                        console.log(error)    
                         loadingButton(false,document.querySelector('#submit-import'),'fa fa-save','Importar')    
                         ShowMessages(error) 
                       })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-import'),'fa fa-save','Importar')    
            ShowMessages(response)
        }
    }
    
    const downloadPDF = () => {
        const linkSource = `data:application/pdf;base64,${dataResult.reason}`;
        const downloadLink = document.createElement("a");
        const fileName = "55Zap - Erros de Importação.pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    useEffect(() => {
        if (showModal)
            setData({},true)
    },[showModal])
   
    const getData = async() => {
        try {
            await api.get('/getDataCampaing')
                    .then(response => { 
                        let _tags  = []
                        let _check = []

                        response.data.tags.map(item => {
                            if (item.tags.length > 1) {
                                item.tags.map(item1 => {
                                    _check = _tags.filter(_filter => {
                                        return (_filter.tags[0] === item1)
                                    })

                                    if (_check.length === 0)
                                        _tags.push({'tags' : [item1]}) 
                                })
                            } else {
                                _check = _tags.filter(_filter => {
                                    return _filter.tags[0] === item.tags[0]
                                })

                                if (_check.length === 0)   
                                    _tags.push(item)
                            }
                        })  
                        setDataTags(_tags)
                    })
                    .catch( error => {      
                        ShowMessages(error) 
                    })
        } catch (response) { 
            ShowMessages(response)
        }
    }

    useEffect(() => {
        getData()
        setStatistic('modals\\modal-import')
    } ,[])

    return (
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogClassName=" width500" dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content"> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-cloud-arrow-up"></i> Propriedades de Importações
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <div className='box-shadow'>
                        {showResult ? 
                            <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                                <div style={{width:"450px", height:"250px", padding:"20px 15px", fontSize:"16px"}} className="box-shadow">
                                    <div style={{display:"flex"}}  className="text-danger">
                                        <div style={{width:"340px", display:"flex",alignItems:"center"}}>
                                            <span style={{fontWeight:"bold"}}>Total de Registros Ignorados:</span>
                                        </div>
                                        <div style={{width:"75px", textAlign:"right", display:"flex",alignItems:"center"}}>
                                            {dataResult.ignored || 0}
                                        </div>
                                        <div style={{marginLeft:"10px", width:"40px", display:"flex",alignItems:"center"}}>
                                            <button type="button" className="btn btn-danger" onClick={e => downloadPDF()} title="Baixar arquivo de erros de importação">
                                                <i className="fa fa-font-awesome"/>
                                            </button>
                                        </div>

                                    </div>
                                    <div style={{display:"flex", marginTop:"15px"}}  className="text-success">
                                        <div style={{width:"340px"}}>
                                            <span style={{fontWeight:"bold"}}>Total de Registros Inseridos:</span>
                                        </div>
                                        <div style={{width:"75px", textAlign:"right", display:"flex",alignItems:"center"}}>
                                            {dataResult.inserted || 0}
                                        </div>
                                        <div style={{marginLeft:"10px", width:"40px", display:"flex",alignItems:"center"}}>
                            
                                        </div>
                                    </div>
                                    <div style={{display:"flex", marginTop:"15px"}}  className="text-warning">
                                        <div style={{width:"340px"}}>
                                            <span style={{fontWeight:"bold"}}>Total de Registros Alterados:</span>
                                        </div>
                                        <div style={{width:"75px", textAlign:"right", display:"flex",alignItems:"center"}}>
                                            {dataResult.updated || 0}
                                        </div>
                                        <div style={{marginLeft:"10px", width:"40px", display:"flex",alignItems:"center"}}>
                            
                                        </div>
                                    </div>
                                    <div style={{display:"flex", marginTop:"15px"}} className="text-info">
                                        <div style={{width:"340px"}}>
                                            <span style={{fontWeight:"bold"}} >Total de Registros Processados:</span>
                                        </div>
                                        <div style={{width:"75px", textAlign:"right", display:"flex",alignItems:"center"}}>
                                            {dataResult.total || 0}
                                        </div>
                                        <div style={{marginLeft:"10px", width:"40px", display:"flex",alignItems:"center"}}>
                            
                                        </div>
                                    </div>
                                    <div style={{display:"flex", marginTop:"35px"}} className="text-info">
                                        <button type="button" className="btn btn-success" onClick={e => handleShow()}><i className="fa fa-file-import"/> Importar mais...</button>
                                    </div>
                                </div>
                            </div>
                        :
                            <div>               
                                <div style={{display:"flex"}}>
                                    <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                        <input type="checkbox" checked={importUnnamed} value={importUnnamed} onClick={e => setImportUnnamed(!importUnnamed)}  />
                                        <span style={{ marginTop: "5px" }}></span>
                                    </label>
                                    <span style={{marginLeft:"5px",marginTop:"5px"}}>Importar contatos sem nome?</span>
                                </div>
                                <div style={{display:"flex"}}>
                                    <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                        <input type="checkbox" checked={useTagsFile} value={useTagsFile} onClick={e => {setUseTagsFile(!useTagsFile)
                                                                                                                        if (!useTagsFile) setTagBlock(false)}}  />
                                        <span style={{ marginTop: "5px" }}></span>
                                    </label>
                                    <span style={{marginLeft:"5px",marginTop:"5px"}}>Usar as tags do arquivo?</span>
                                </div>
                                <div style={{display:"flex"}}>
                                    <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                        <input type="checkbox" checked={tagBlock} value={tagBlock} onClick={e => {setTagBlock(!tagBlock)
                                                                                                                if (!tagBlock) setUseTagsFile(false)}}  />
                                        <span style={{ marginTop: "5px" }}></span>
                                    </label>
                                    <span style={{marginLeft:"5px",marginTop:"5px"}}>Importar como contato bloqueado?</span>
                                </div>
                                {!useTagsFile && !tagBlock?
                                    <div style={{display:"flex", marginTop:"15px"}}>
                                        <div style={{width:"300px"}}>
                                            <label>Tags</label>
                                            <div className="input-group">
                                                <TagsInput value={tags} onChange={e => handleTagInput(e)} beforeAddValidate={e => addValidationTag(e)}/>
                                            </div>
                                            <small className="text-danger">Após digitar a <span style={{fontWeight:"bold"}}>Tag</span>, aperte <span style={{fontWeight:"bold"}}>Enter</span></small>
                                        </div>     
                                    
                                        <div style={{marginLeft:"15px"}}>
                                            <button title="Click aqui para incluir uma tag já existente" type="button" className="btn btn-success" style={{marginTop:"15px"}} onClick={e => handleTagInput(addValidationTag(tag) ? [...tags,tag] : tags)}><i className="fa fa-arrow-left"></i></button>
                                        </div>       
                        
                                        <div style={{marginLeft:"15px"}}>
                                            <div style={{width:"200px"}}>
                                                <span>Tags já existentes</span>
                                                <select className="form-select" value={tag} onChange={e => setTag(e.target.value)} style={{minHeight:"37px"}}>
                                                    <option value="">Selecione</option>
                                                    {dataTags.map((item) => {
                                                        return(<option value={item.tags}>{item.tags}</option>)
                                                    })} 
                                                </select>
                                            </div>
                                            <small className="text-danger">Selecione uma <span style={{fontWeight:"bold"}}>Tag</span> e click no botão ao lado para inserir</small>
                                        </div>                                    
                                    </div>  
                                : ''}
                                <div style={{display:"flex", marginTop:"15px", flexDirection:"column"}}>
                                    <div>
                                        <span>Informe o arquivo que será importado</span>
                                    </div>  
                                    <div className="input-group">
                                        <input type="file" accept=".xlsx"  onChange={e => setFile(e.target.files[0])} className="form-control" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" aria-label="Upload"/>
                                    </div>
                                </div>       
                            </div>
                        }
                    </div>
                   
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="button" className="btn btn-info" onClick={e => download(e)}>
                        <em className="fa fa-file-excel"></em> Download arquivo modelo
                    </Button>    
                    {!showResult ? 
                        <>
                            <Button type="button" id="submit-import" onClick={e => handleSubmit(e)}>
                                <em className="fa fa-save"></em> Importar
                            </Button>          
                        </>
                    : ''}
                    <Button type="button" className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}