/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import ShowMessages from '../../components/Exceptions';
import api from '../../services/api';
import {
    ACCEPT_TERMS,
    EXPIRES_IN,
    IDCOMPANY,
    IDUSER,
    isAuthenticated,
    ReadDataStorage,
    REGISTRATION,
    TOKEN_KEY,
    USERNAME,
    USER_DATA,
    IDPLAN,
    CONTRACTSTATUS,
    WriteDataStorage,
} from '../../services/auth';
import logo from './../../assets/logo.png';

import { TextInput, ValidationForm } from 'react-bootstrap4-form-validation';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { getCookieMessage, setCookieMessage } from './../../components/Cookies';

import loadingButton from './../layouts/Buttons';

import setStatistic from './../../services/apiStatistic';

export default function Login() {
    const [update, setUpdate]                   = useState(false);
    const [registration, setRegistration]       = useState('');
    const [password, setPassword]               = useState('');
    const [oldpassword, setOldPassword]         = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [remember, setRemember]               = useState(false);
    const [idUser,setIdUser]                    = useState('')

    const matchPassword = (value) => {
        return value && value === password;
    };

    useEffect(() => {
        if (getCookieMessage('LoginMsg', '') !== '') {
            NotificationManager.error(getCookieMessage('LoginMsg', ''), 'Erro!', 4000);
            setCookieMessage('LoginMsg', '');
        }

        let _passOnFocus = false;
        if (ReadDataStorage(REGISTRATION) !== '' && ReadDataStorage(REGISTRATION) !== null) {
            setRegistration(ReadDataStorage(REGISTRATION));
            setRemember(true);
            _passOnFocus = true;
        }

        if (isAuthenticated()) window.location.href = '/';
        else {
            if (_passOnFocus) document.querySelector('#password').focus();
            else document.querySelector('#registration').focus();
        }
    }, []);

    useEffect(() => {
        setStatistic('login');
    }, []);

    const handleSubmit = async (event) => {
        if (update)
            loadingButton(
                true,
                document.querySelector('#update-password'),
                document.querySelector('#update-password').children[0].classList,
                document.querySelector('#update-password').lastChild.nodeValue,
            );
        else
            loadingButton(
                true,
                document.querySelector('#submit'),
                document.querySelector('#submit').children[0].classList,
                document.querySelector('#submit').lastChild.nodeValue,
            );

        event.preventDefault();
        try {
            await api
                .post('/authenticate', {
                    registration,
                    password,
                    update,
                    oldpassword,
                    idUser
                })
                .then((response) => {
                    if (response.ok) {
                        if (response.data.update) {
                            setUpdate(true);
                            setOldPassword(response.data.password);
                            setPassword('')
                            setIdUser(response.data.iduser)
                        } else if (response.data.redirect) {
                            ShowMessages(response.data);
                            setUpdate(false);
                            setPassword('');
                        } else {
                            if (response.data.status === 500) {
                                if (![2,5].includes(response.data.idstatusplan)) { 
                                    ShowMessages(response.data)
                                    localStorage.clear();
                                    loadingButton(false, document.querySelector('#submit'), 'fa fa-sign-in-alt', 'Acessar');
                                    setPassword('');
                                    return
                                }
                            }
 
                            setUpdate(false);
                            const { token, user, idcompany } = response.data;
                            WriteDataStorage(TOKEN_KEY, JSON.stringify(token));
                            WriteDataStorage(USER_DATA, JSON.stringify(user));
                            WriteDataStorage(IDCOMPANY, idcompany);
                            WriteDataStorage(USERNAME, response.data.user.name);
                            WriteDataStorage(IDUSER, response.data.user.iduser);
                            WriteDataStorage(EXPIRES_IN, response.data.expires_in);
                            WriteDataStorage(ACCEPT_TERMS, response.data.acceptedterm);
                            WriteDataStorage(CONTRACTSTATUS, response.data.idstatusplan)
                            WriteDataStorage(IDPLAN,JSON.stringify(response.data.amendment))

                            if (remember === true)
                                WriteDataStorage(REGISTRATION, registration);
                            else
                                WriteDataStorage(REGISTRATION, '');
                            if ([2,5].includes(response.data.idstatusplan)) {
                                window.location.href = '/payment'
                            } else
                                window.location.href = '/'
                        }
                    } else {
                        if (update) loadingButton(false, document.querySelector('#update-password'), 'fa fa-key', 'Alterar Senha');
                        else loadingButton(false, document.querySelector('#submit'), 'fa fa-sign-in-alt', 'Acessar');
                    }
                });
        } catch (response) {
            localStorage.clear();
            if (update) loadingButton(false, document.querySelector('#update-password'), 'fa fa-key', 'Alterar Senha');
            else loadingButton(false, document.querySelector('#submit'), 'fa fa-sign-in-alt', 'Acessar');

            setRegistration('');
            setPassword('');
        }
    };

    return (
        <>
            <div className="background"></div>
            <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <NotificationContainer />
                <div className="content">
                    <div className="block-center wd-xl">
                        <div className="card card-flat">
                            <div className="card-header text-center bg-blue">
                                <Link to="/login">
                                    <img className="block-center rounded imgWidth" src={logo} alt={process.env.REACT_APP_NAME} />
                                </Link>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    padding: '5px',
                                    fontSize: '12px',
                                    color: '#cacaca',
                                }}
                            >
                                Versão: {process.env.REACT_APP_VERSION}
                            </div>
                            <div className="card-body">
                                <ValidationForm onSubmit={handleSubmit} setFocusOnError={true}>
                                    {update === false ? (
                                        <div>
                                            <div className="input-group mb-3">
                                                <TextInput
                                                    name="registration"
                                                    id="registration"
                                                    type="text"
                                                    placeholder="Usuário"
                                                    required
                                                    maxLength={60}
                                                    errorMessage={{ required: 'Por favor, informe um usuário válido!' }}
                                                    value={registration}
                                                    className="form-control "
                                                    onChange={(e) => setRegistration(e.target.value.toUpperCase())}
                                                    autoComplete="off"
                                                />
                                            </div>
                                            <div className="input-group mb-2">
                                                <TextInput
                                                    name="password"
                                                    id="password"
                                                    type="password"
                                                    placeholder="Senha"
                                                    required
                                                    maxLength={20}
                                                    errorMessage={{ required: 'Por favor, informe uma senha válida!' }}
                                                    value={password}
                                                    className="form-control"
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    autoComplete="off"
                                                />
                                            </div>
                                            <div className="input-group mb-4">
                                                <div className="checkbox c-checkbox">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            checked={remember}
                                                            className="form-check-input"
                                                            value={remember}
                                                            onChange={() => setRemember(!remember)}
                                                        />
                                                        <span className="fa fa-check"></span>Lembrar minha matrícula
                                                    </label>
                                                </div>
                                            </div>

                                            <button
                                                className="btn btn-block btn-theme mt-3"
                                                type="submit"
                                                id="submit"
                                                style={{ width: '100%' }}
                                            >
                                                <em className="fa fa-sign-in-alt"></em> Acessar
                                            </button>

                                            <div className="clearfix" style={{ marginTop: '15px' }}>
                                                <div className="float-right">
                                                    <Link to="/forgot" className="text-muted">
                                                        Esqueceu sua senha?
                                                    </Link>
                                                </div>
                                                <div>
                                                    <Link to="/register" className="btn btn-success" style={{ width: "100%", marginTop: "15px" }}><em className="fa fa-user-plus"></em> Cadastre Agora</Link>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            <div className="input-group mb-2">
                                                <TextInput
                                                    name="password"
                                                    id="password1"
                                                    type="password"
                                                    placeholder="Informe uma nova senha"
                                                    required
                                                    errorMessage={{
                                                        required: 'Por favor, informe uma senha válida!',
                                                        pattern: 'A senha devem conter pelo menos 6 dígitos.',
                                                    }}
                                                    value={password}
                                                    autoFocus={true}
                                                    pattern="(?=.*[A-Z,0-9,!@#$%&*()-=+,.;:/}{\|}]).{6,}"
                                                    className="form-control"
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    autoComplete="off"
                                                />
                                            </div>
                                            <div className="input-group mb-2">
                                                <TextInput
                                                    name="confirmPassword"
                                                    id="confirmPassword"
                                                    type="password"
                                                    placeholder="Repita a nova senha"
                                                    required
                                                    errorMessage={{
                                                        required: 'Por favor, informe uma senha válida!',
                                                        validator: 'As senhas informadas não conferem.',
                                                        pattern: 'A senha devem conter pelo menos 6 dígitos.',
                                                    }}
                                                    value={confirmPassword}
                                                    validator={matchPassword}
                                                    pattern="(?=.*[A-Z,0-9,!@#$%&*()-=+,.;:/}{\|}]).{6,}"
                                                    className="form-control"
                                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                                    autoComplete="off"
                                                />
                                            </div>
                                            <button
                                                className="btn btn-block btn-theme mt-3"
                                                type="submit"
                                                id="update-password"
                                                style={{ width: '100%' }}
                                            >
                                                <em className="fa fa-key"></em> Alterar Senha
                                            </button>
                                        </div>
                                    )}
                                </ValidationForm>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
