import React, { useState, useEffect, useRef  } from 'react'
import { Link, useParams } from 'react-router-dom'

import logo from './../../assets/logo.png'
import api from '../../services/api'

import ReCAPTCHA from "react-google-recaptcha"

import MaskWithValidation from '../../components/maskInput';
import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';

import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import  loadingButton  from '../layouts/Buttons'
import { cpf, cnpj } from 'cpf-cnpj-validator';
import setStatistic from '../../services/apiStatistic'
import ShowMessages from '../../components/Exceptions';
import consultarCNPJ from 'consultar-cnpj';
import { sanatizeData } from './../../components/Utils'

export default function Register() {
    const { plan, period } = useParams();

    const [idkindofperson, setIdkindofperson] = useState(0)
    const [cpfcnpj, setCpfcnpj]               = useState('')
    const [name, setName]                     = useState('')
    const [whatsapp, setWhatsapp]             = useState('')
    const [email, setEmail]                   = useState('')
    const [user, setUser]                     = useState('')
    const [password, setPassword]             = useState('')
    const [state, setState]                   = useState(0)
    const [messageuser,setMessageUser]        = useState('')
    
    const captchaRef = useRef(null)

    useEffect(() => {
        setStatistic('Register')
    } ,[])


    const handleCNPJ = async(value) => {
        setCpfcnpj(value)
        if (value.replace(/\D/g,'').length === 14) {
            try {
                const empresa   = await consultarCNPJ(value.replace(/\D/g,''), '');
  
                setName(empresa.razao_social)
            } catch (e) {
                ShowMessages(e);
            }
        } 
    }

    const handleSubmit = async(event) => {
        event.preventDefault()
        let token = captchaRef.current.getValue();
        if (token === '') {
            ShowMessages({ status : 500, message : "Clique em EU NÃO SOU UM ROBÔ!"})
            return
        }

        if (messageuser.indexOf('cadastrado') > 0) {
            ShowMessages({ status : 500, message : "Usuário já cadastrado no sistema!"})
            return   
        }

        loadingButton(true,document.querySelector('#submit'),document.querySelector('#submit').children[0].classList,document.querySelector('#submit').lastChild.nodeValue)

        try {
  
            await api.post('/register', {
                idkindofperson,
                cpfcnpj,
                name,
                whatsapp,
                email,
                password,
                user,
                token,
                plan, 
                period 
            })
                .then(response => {
                    if (response.ok) {
                        console.log(response.data)
                        if (response.data.status === 200)
                            setState(1)
                        
                    } else if (response.status === 401) {
                        window.location.href = '/login'
                    }
                    loadingButton(false,document.querySelector('#submit'),'fa fa-user-plus','Cadastrar e Ativar Teste')
                })
        } catch (response) {
            console.log(response)
            loadingButton(false,document.querySelector('#submit'),'fa fa-user-plus','Cadastrar e Ativar Teste')
        }
    }

    const OpenLogin = () => {
        window.location.href = '/login'
    }

    useEffect(() => {
        const checkUser = async () => {
            await api.post('/register/validation', {
                user,
            })
                .then(response => {
                    setMessageUser(response.data.message)
                })
        }
        if (user !== '')
            checkUser()
        else 
            setMessageUser('')
    },[user])

    return (
        <>
        <div className="background">
        </div>
        <div style={{height: "100vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <NotificationContainer />
            <div className="content">
                <div className={`block-center box-register `}>
                    <div className="card card-flat">
                        <div className="card-header text-center bg-blue">
                            <Link to="/login">
                                <img className="block-center rounded imgWidth" src={logo} alt={process.env.REACT_APP_NAME} />
                            </Link>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", padding: "5px", fontSize: '12px', color: "#cacaca" }}>
                            Versão: {process.env.REACT_APP_VERSION}
                        </div>
                        <div className="card-body body-register">
                            {state === 0 ?
                                <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} autocomplete="off"> 
                                <div>
                                    <label>Tipo de Pessoa</label>
                                    <select className="form-select" value={idkindofperson} onChange={e => setIdkindofperson(parseInt(e.target.value))}>
                                        <option value="0">Física</option>
                                        <option value="1">Jurídica</option>
                                    </select>
                                </div> 
                                <div>
                                    <label>{idkindofperson === 0 ? 'CPF' : 'CNPJ'}</label>
                                    <MaskWithValidation
                                        name="cpf"
                                        id="cpf"
                                        inputmode="numeric"
                                        type="text"
                                        required
                                        validator={value => (idkindofperson === 0 ? cpf.isValid(value) : cnpj.isValid(value))}
                                        errorMessage={{ required: `Por favor, informe um ${idkindofperson === 0 ? 'CPF' : 'CNPJ'}!`, validator: `${idkindofperson === 0 ? 'CPF' : 'CNPJ'} inválido!`}}
                                        className="form-control "
                                        value={cpfcnpj}
                                        onChange={e => handleCNPJ(e.target.value)}
                                        autoComplete="off"
                                        mask={idkindofperson === 0 ? [/[0-9]/,/[0-9]/,/[0-9]/,'.',/[0-9]/,/[0-9]/,/[0-9]/,'.',/[0-9]/,/[0-9]/,/[0-9]/,'-',/[0-9]/,/[0-9]/] 
                                                                   : [/[0-9]/,/[0-9]/,'.',/[0-9]/,/[0-9]/,/[0-9]/,'.',/[0-9]/,/[0-9]/,/[0-9]/,'/',/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,'-',/[0-9]/,/[0-9]/] }/>
                                </div> 
                                <div>
                                    <label>{idkindofperson === 0 ? 'Nome' : 'Razão Social'}</label>
                                    <TextInput  
                                        name="name"
                                        id="name"
                                        type="text"
                                        required
                                        maxLength={100}
                                        errorMessage={{ required: `Por favor, informe um ${idkindofperson === 0 ? 'Nome' : 'Razão Social'}!` }}
                                        value={name}
                                        className="form-control "
                                        onChange={e => setName(e.target.value)}
                                        autoComplete="off"/>
                                </div>   
                                <div>
                                    <label>WhatsApp</label>
                                    <MaskWithValidation
                                        name="whatsapp"
                                        id="whatsapp"
                                        type="text"
                                        inputmode="numeric"
                                        required
                                        errorMessage={{ required: `Por favor, informe um WhatsApp` }}
                                        className="form-control "
                                        value={whatsapp}
                                        onChange={e => setWhatsapp(e.target.value)}
                                        autoComplete="off"
                                        mask={['(',/[0-9]/,/[0-9]/,')',' ',/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,'-',/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/] }/>
                                </div> 
                                <div>
                                    <label>Email</label>
                                    <TextInput  
                                        name="email"
                                        id="email"
                                        type="email"
                                        required
                                        maxLength={150}
                                        errorMessage={{ required: `Por favor, informe um email`, validator : "Informe uma email válido", type : "Informe uma email válido" }}
                                        value={email}
                                        className="form-control "
                                        onChange={e => setEmail(e.target.value)}
                                        autoComplete="off"/>
                                </div> 
                                <div>
                                    <label>Usuário</label>
                                    <TextInput  
                                        name="user"
                                        id="user"
                                        type="text"
                                        required
                                        maxLength={30}
                                        errorMessage={{ required: `Por favor, informe um usuário` }}
                                        value={user}
                                        className="form-control "
                                        onChange={e => setUser(sanatizeData(e.target.value.toString().toUpperCase(),false,false))}
                                        autofill="off"
                                        autoComplete="off"/>
                                    <small className={messageuser.indexOf('válido') > 0 ? 'text-success' : 'text-danger'}>{messageuser}</small>
                                </div>
                                <div>
                                    <label>Senha</label>
                                    <TextInput  
                                        name="password"
                                        id="password"
                                        type="password"
                                        required
                                        maxLength={15}
                                        errorMessage={{ required: `Por favor, informe uma senha` }}
                                        value={password}
                                        className="form-control "
                                        onChange={e => setPassword(e.target.value)}
                                        autofill="off"
                     
                                        autoComplete="off"/>
                                </div>
                                <div style={{marginTop:"15px"}}>
                                    <ReCAPTCHA ref={captchaRef} sitekey={process.env.REACT_APP_SITE_KEY}/>
                                </div>
                                
                                <button className="btn btn-block btn-theme mt-3" type="submit" id='submit' style={{width:"100%"}}><em className="fa fa-user-plus"></em> Cadastrar e Ativar Teste</button>
                            </ValidationForm>

                         : 
                         
                         <div>
                            <div className='bg alert alert-success' style={{padding:"35px", borderRadius:"5px", display:"flex"}}>
                                <div style={{display:"flex",alignItems:"center"}}>
                                    <i style={{fontSize:"32px"}} className='fa fa-champagne-glasses'/>
                                </div>
                                <div style={{marginLeft:"15px", fontSize:"20px", textAlign:"center"}}>
                                    Parabéns, o seu cadastro foi realizado com sucesso!
                                </div>
                            </div>
                            <div className='bg alert alert-warning' style={{padding:"35px", borderRadius:"5px", display:"flex", marginTop:"15px", justifyContent:"center", flexDirection:"column"}}>
                                <div style={{fontSize:"16px", textAlign:"center", color:"#000", fontWeight:"bold"}}>
                                    Você possui 2 dias para testar o nosso produto.
                                </div>
                                <div style={{extAlign:"center", marginTop:"25px", display:"flex",justifyContent:"center"}}>
                                    <button className='btn btn-success' onClick={e => OpenLogin(e)}><i className="fa fa-arrow-up-right-from-square"/> Vamos ao trabalho?</button>
                                </div>
                            </div>

                            
                         </div>
                         
                         }
                          
                         </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )

}

